import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box } from 'grommet'
import Video from '../reusables/video'
class Multiplication extends Component {
  constructor(props) {
    super()

    this.state = {
      tab: `tabs.tab1`,
    }
  }

  render() {
    const tab = this.state.tab
    return (
      <Box margin="small" pad="large" align="center" background="light-3">
        <h3>
          <FormattedMessage id={`${tab}.tab`} />
        </h3>
        <Video tab={this.state.tab} />
      </Box>
    )
  }
}

export default Multiplication
