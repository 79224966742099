import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Grommet, Tab, Tabs } from 'grommet'
import { grommet } from 'grommet/themes'
import { injectIntl } from 'react-intl'

function withTabs(WrappedTabs) {
  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {}

      this.onActive = this.onActive.bind(this)
    }

    onActive(index) {
      this.setState({ index })
    }

    render() {
      const { index } = this.state
      const preInjectedTabs = ({ intl }) => (
        <Box
          a11yTitle="tabs"
          direction="column"
          justify="center"
          align="center"
          pad="xlarge"
          background="dark-2"
        >
          <h2>
            <FormattedMessage id="tabs.title" />
          </h2>
          <Tabs flex activeIndex={index} onActive={this.onActive}>
            {WrappedTabs.map((tab, index) => (
              <Tab
                key={index}
                title={intl.formatMessage({ id: `tabs.tab${index}.tab` })}
              >
                <Box
                  fill
                  overflow="scroll"
                  pad="lage"
                  align="center"
                  backgroun="light-1"
                >
                  {tab}
                </Box>
              </Tab>
            ))}
          </Tabs>
        </Box>
      )
      const InjectedTabs = injectIntl(preInjectedTabs)
      return <InjectedTabs />
    }
  }
}

export default withTabs
