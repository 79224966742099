import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box } from 'grommet'
import Video from '../reusables/video'
import Centered from '../reusables/centered';
class Addition extends Component {
  constructor(props) {
    super()

    this.state = {
      tab: `tabs.tab0`,
    }
  }

  render() {
    const tab = this.state.tab
    return (
      <Box margin="small" align="center" background="light-3">
        <h3>
          <FormattedMessage id={`${tab}.tab`} />
        </h3>
        <Video tab={this.state.tab} />
        <Centered index="0"/>
      </Box>
    )
  }
}

export default Addition
