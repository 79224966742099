import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH3,
  StyledSpan,
  StyledUnderlinedH2,
} from './styles'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Button from '../reusables/button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledImageContainer = styled(Box)`
  height: 125px;
`

const StyledContentContainer = styled(Box)``

const Columns = ({ index, data, shade, intl }) => {
  const columns = `columns.columns${index}`
  const title = intl.formatMessage({
    id: `${columns}.title`,
  })
  const hasTitle = title !== 'null'
  const description = intl.formatMessage({
    id: `${columns}.description.description`,
  })
  const hasDescription = description !== 'null'
  const isRichText =
    intl.formatMessage({
      id: `${columns}.description.richText`,
    }) !== 'null'

  const buttons = intl.formatMessage({
    id: `${columns}.buttons.number`,
  })
  const number = intl.formatMessage({
    id: `${columns}.number`,
  })
  const totalColumns = intl.formatMessage({
    id: `${columns}.columns`,
  })

  const backgroundImage = intl.formatMessage({
    id: `${columns}.backgroundImage`,
  })

  return (
    <StyledContainer
      componentName="Columns"
      shade={shade}
      data={data}
      backgroundImage={backgroundImage}
    >
      <StyledContentContainer align="center" justify="center" width="full">
        {hasTitle && <StyledUnderlinedH2>{title}</StyledUnderlinedH2>}
        {hasDescription && !isRichText ? (
          <StyledSpan>{description}</StyledSpan>
        ) : (
          hasDescription &&
          isRichText && (
            <StyledSpan dangerouslySetInnerHTML={{ __html: description }} />
          )
        )}

        <StyledColumnsContainer
          columns={totalColumns}
          align="center"
          justify="center"
        >
          {Array.from(Array(Number(number)).keys()).map((column, index) => {
            const section = `${columns}.column${index}`
            const columnTitle = intl.formatMessage({
              id: `${section}.title`,
            })
            const hasColumnTitle = columnTitle !== 'null'
            const columnDescription = intl.formatMessage({
              id: `${section}.description.description`,
            })
            const hasColumnDescription = columnDescription !== 'null'
            const isColumnRichText =
              intl.formatMessage({
                id: `${section}.description.richText`,
              }) !== 'null'

            const columnButtons = intl.formatMessage({
              id: `${section}.buttons.number`,
            })

            const columnImages = intl.formatMessage({
              id: `${section}.images.number`,
            })

            return (
              <StyledColumnContainer
                align="start"
                key={shortid.generate()}
                justify="center"
                width={"50%"}
              >
                {columnImages > 0 && (
                  <Box
                    direction="row-responsive"
                    gap="medium"
                    pad={{ vertical: 'medium' }}
                  >
                    {Array.from(Array(Number(columnImages)).keys()).map(i => {
                      const columnImage = `${section}.images.image${i}`

                      const imageLink = intl.formatMessage({
                        id: `${columnImage}.link`,
                      })
                      const hasLink = imageLink !== 'null'

                      const gif = intl.formatMessage({
                        id: `${columnImage}.gif`,
                      })
                      const isGif = gif !== 'null'
                      const iconPath = intl.formatMessage({
                        id: `${columnImage}.path`,
                      })
                      let imagePath = 'imagePath'
                      if (!isGif) {
                        imagePath = data[`${iconPath}`].childImageSharp.fixed
                      } else {
                        imagePath = require(`./gifs/${iconPath}.gif`)
                      }
                      return (
                        <StyledImageContainer key={shortid.generate()}>
                          {hasLink ? (
                            <a href={imageLink} target="_blank">
                              {isGif ? (
                                <StyledImageContainer>
                                  <img src={imagePath} />
                                </StyledImageContainer>
                              ) : (
                                <StyledImageContainer>
                                  <Img
                                    imgStyle={{ objectFit: 'contain' }}
                                    fixed={imagePath}
                                  />
                                </StyledImageContainer>
                              )}
                            </a>
                          ) : isGif ? (
                            <StyledImageContainer>
                              <img src={imagePath} />
                            </StyledImageContainer>
                          ) : (
                            <StyledImageContainer>
                              <Img
                                imgStyle={{ objectFit: 'contain' }}
                                fixed={imagePath}
                              />
                            </StyledImageContainer>
                          )}
                        </StyledImageContainer>
                      )
                    })}
                  </Box>
                )}
                {hasColumnTitle && <StyledH3>{columnTitle}</StyledH3>}
                {hasColumnDescription && !isColumnRichText ? (
                  <StyledSpan>{columnDescription}</StyledSpan>
                ) : (
                  hasColumnDescription &&
                  isColumnRichText && (
                    <StyledSpan
                      dangerouslySetInnerHTML={{ __html: columnDescription }}
                    />
                  )
                )}
                {columnButtons > 0 && (
                  <Box
                    direction="row-responsive"
                    gap="medium"
                    pad={{ vertical: 'medium' }}
                  >
                    {Array.from(Array(Number(columnButtons)).keys()).map(i => {
                      const columnButton = `button${i}`
                      const columnButtonLabel = intl.formatMessage({
                        id: `${section}.buttons.${columnButton}.label`,
                      })
                      const columnButtonLink = intl.formatMessage({
                        id: `${section}.buttons.${columnButton}.link`,
                      })
                      return (
                        <Button
                          key={shortid.generate()}
                          label={columnButtonLabel}
                          link={columnButtonLink}
                          light={
                            shade === 'light' || shade === 'grey' ? true : false
                          }
                        />
                      )
                    })}
                  </Box>
                )}
              </StyledColumnContainer>
            )
          })}
        </StyledColumnsContainer>
        {buttons > 0 && (
          <Box
            direction="row-responsive"
            gap="medium"
            pad={{ vertical: 'medium' }}
          >
            {Array.from(Array(Number(buttons)).keys()).map(i => {
              const button = `button${i}`
              const buttonLabel = intl.formatMessage({
                id: `${columns}.buttons.${button}.label`,
              })
              const buttonLink = intl.formatMessage({
                id: `${columns}.buttons.${button}.link`,
              })
              return (
                <Button
                  key={shortid.generate()}
                  label={buttonLabel}
                  link={buttonLink}
                  light={shade === 'light' || shade === 'grey' ? true : false}
                />
              )
            })}
          </Box>
        )}
      </StyledContentContainer>
    </StyledContainer>
  )
}

Columns.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(Columns)
