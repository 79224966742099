import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

const Video = ({tab, intl }) => {
  const src = `${tab}.video`;
  return (
      <iframe
        width="90%"
        height="auto"
        src={intl.formatMessage({ id: src })}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
  )
}

export default injectIntl(Video)
